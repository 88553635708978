import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (lng: string) => {
    // i18n.changeLanguage(lng)
    localStorage.setItem('language', lng)
    window.location.reload()
  }

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={'sm'}>
        {i18n.language === 'en' ? 'English' : 'עברית'}
      </MenuButton>
      <MenuList minW={'96px'} fontSize={'sm'}>
        <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
        <MenuItem onClick={() => changeLanguage('he')}>עברית</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default LanguageSwitcher
